const originalFetch = globalThis.fetch;

export const registerFetchInterceptors = () => {
  globalThis.fetch = (resource, options = {}) => {
    const csrfToken = document.cookie?.match(/XSRF-TOKEN=([^;]*)/)?.[1];

    if (csrfToken) {
      if (!options?.headers) {
        options.headers = {};
      }

      options.headers['X-XSRF-TOKEN'] = decodeURIComponent(csrfToken);
    }

    return originalFetch(resource, options);
  };
};

export default registerFetchInterceptors;
