import { MainLayout } from '@webfox/webfox-ui';
import 'Style/app.css';
import { initInertiaApp } from 'Support/init';
import registerFetchInterceptors from 'Support/registerFetchInterceptors';

registerFetchInterceptors();

initInertiaApp({
  resolve: async (name) => {
    const comps = import.meta.glob('./Pages/**/*.{tsx,jsx}');
    const match = comps[`./Pages/${name}.tsx`] ?? comps[`./Pages/${name}.jsx`];
    if (!match) {
      throw new Error(`You've attempted to load the Inertia.js page 'Pages/${name}.{tsx,jsx}', but no file with that name exists.`);
    }

    return (await match()).default;
  },
  layout: MainLayout,
});
