import { createInertiaApp, Head } from '@inertiajs/react';
import * as React from 'react';
import { createElement, StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { toast, ToastContainer } from 'react-toastify';
import { FormErrorContextProvider, InitialApplicationContext } from 'Support/Contexts';
import 'Support/yupExtensions';

// export const initGoogleAnalytics = () => {
//   if (typeof window.gtag === 'function') {
//     let initialPageView = true;
//     Inertia.on('navigate', (event) => {
//       if (initialPageView) {
//         initialPageView = false;
//       } else {
//         window.gtag('event', 'page_view', {
//           'page_location': event.detail.page.url,
//         });
//       }
//     });
//   }
// };
//
// export const initErrorTracking = async () => {
//   if (window.sentryDsn) {
//     const Sentry = await import('@sentry/react');
//     Sentry.init({
//       dsn: window.sentryDsn,
//       integrations: [new Sentry.BrowserTracing()],
//       environment: window.sentryEnvironment,
//       tracesSampleRate: window.sentryTracesSampleRate,
//     });
//   }
// };

export const initInertiaApp = ({ resolve: resolveComponent, layout: MainLayout, progress, errorTracking = true, analytics = true }) => {
  // if (errorTracking) {
  //   initErrorTracking();
  // }
  //
  // if (analytics) {
  //   initGoogleAnalytics();
  // }

  return createInertiaApp({
    progress: progress === undefined ? { showSpinner: true } : progress,
    page: window.inertiaInitialPageData,
    resolve(name) {
      return resolveComponent(name).catch((e) => {
        throw new Error(`Inertia failed to load the page component for "${name}".`, {
          cause: e,
        });
      });
    },
    setup({ el, App, props }) {
      const initialApplication = { application: props.initialPage.props.application };

      createRoot(el).render(
        <StrictMode>
          <InitialApplicationContext.Provider value={initialApplication}>
            <App {...props}>
              {({ key, props, Component }) => {
                const componentLayout = Component.layout;
                const page = createElement(Component, { key, ...props });

                const defaultLayout = (children) => (
                  <div>
                    <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="light" position="top-right" />
                    {children}
                  </div>
                );

                useEffect(() => {
                  if (Array.isArray(page.props.flash)) {
                    page.props.flash.forEach(({ type, message }) => {
                      toast(message, { type });
                    });
                  }
                }, [JSON.stringify(page.props.flash)]);
                let children;
                if (Array.isArray(componentLayout)) {
                  children = componentLayout
                    .concat(page)
                    .reverse()
                    .reduce((children, Layout) => createElement(Layout, { children }));
                } else if (typeof componentLayout === 'function') {
                  children = componentLayout(page, defaultLayout);
                } else {
                  children = defaultLayout(page);
                }

                return (
                  <FormErrorContextProvider>
                    <Head key={page.props.page_title}>
                      <title>{(page.props.page_title ? `${page.props.page_title} | ` : '') + page.props.application.site_name}</title>
                    </Head>
                    {children}
                  </FormErrorContextProvider>
                );
              }}
            </App>
          </InitialApplicationContext.Provider>
        </StrictMode>
      );
    },
  });
};
